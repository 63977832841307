import type { AsVuexStore } from '@zento-lib/components/vuex';

import IMyAccountState from '../../../stores/account/types/MyAccountState';

export const KEY = 'myAccount';

export type IMyAccountStore = AsVuexStore<
  IMyAccountState,
  'getDrawerState' | 'getAccountState',
  'toggleMyAccount',
  'setMyAccount' | 'setModalActive' | 'setDrawerActive'
>;
